import React from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import PhoneField from './shared/PhoneField';

const ContactForm = ({ mode = 'add', onSubmit, onCancel, initialValues }) => {
  const isAdding = mode === 'add';
  const isEditing = mode === 'edit';
  const isDeleting = mode === 'delete';
  const title = isAdding ? 'Agregar Contacto' : isEditing ? 'Editar Contacto' : 'Eliminar Contacto';
  const titleColor = isDeleting ? 'error' : 'primary';
  const submitButtonText = isAdding ? 'Agregar' : isEditing ? 'Editar' : 'Eliminar';
  const submitButtonColor = isDeleting ? 'error' : 'primary';
  
  let validationSchema;

  if (!isDeleting) {
    validationSchema = Yup.object().shape({
      firstName: Yup.string().required('Requerido'),
      lastName: Yup.string().required('Requerido'),
      email: Yup.string().email('Debe ser un email válido').required('Requerido'),
      phoneNumber: Yup.string().required('Requerido').matches(/^\(\d{2}\) \d{3}-\d{5}$/, 'Debe ser un número de celular válido (XX) XXX-XXXXX'),
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6" color={titleColor} style={{ marginBottom: '20px' }}>
            {title}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField 
                name="firstName"
                label="Nombre"
                fullWidth
                {...formik.getFieldProps('firstName')}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                disabled={isDeleting}
                style={{height: '40px', marginBottom: '10px'}}
                autoComplete="no-autocomplete"
                autoCorrect="off"
                spellCheck="false"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                name="lastName"
                label="Apellido"
                fullWidth
                {...formik.getFieldProps('lastName')}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                disabled={isDeleting}
                style={{height: '40px', marginBottom: '10px'}}
                autoComplete="no-autocomplete"
                autoCorrect="off"
                spellCheck="false"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                name="email"
                label="Correo electrónico"
                {...formik.getFieldProps('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                disabled={isDeleting}
                style={{height: '40px', marginBottom: '10px'}}
                autoComplete="no-autocomplete"
                autoCorrect="off"
                spellCheck="false"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PhoneField 
                name="phoneNumber"
                label="Celular"
                {...formik.getFieldProps('phoneNumber')}
                touched={formik.touched}
                errors={formik.errors}
                // error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                // helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                fullWidth
                disabled={isDeleting}
                style={{height: '40px', marginBottom: '10px'}}
                autoComplete="no-autocomplete"
                autoCorrect="off"
                spellCheck="false"
              />
              
            </Grid>
          </Grid>

          <Toolbar style={{ marginTop: '20px', justifyContent: 'space-between' }}>
            <Button 
              variant="outlined" 
              disabled={formik.isSubmitting}
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <Button 
              variant="contained" 
              color={submitButtonColor} 
              type="submit"
              disabled={formik.isSubmitting}
            >
              {submitButtonText}
            </Button>
          </Toolbar>
    </form>
  );
};

export default ContactForm;
