import * as React from 'react';
import dayjs from 'dayjs';
//TODO: Reemplazar x-date-pickers/internals/demo (se utiliza en ) ya que es BasicDateTimePicker --> EventForm una libreria no publica aun y eliminarla de transformIgnorePatterns
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function BasicDateTimePicker(props) {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs(props.value));
  const { label, value, onChange } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker label={label} value={dayjs(value)} onChange={onChange}/>
      </DemoContainer>
    </LocalizationProvider>
  );
}