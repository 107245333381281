import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Notification = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  useImperativeHandle(ref, () => ({
    showNotification(type, customMessage = '') {
      setSeverity(type);
      setMessage(customMessage || (type === 'success' ? props.successMessage : props.errorMessage));
      setOpen(true);
    }
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ zIndex: 9999}}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
});

export default Notification;
