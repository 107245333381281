function AutomatedRemindersIcon() {
    
    return (
        <div style={{ width: '80px', height: '80px', margin: '10px', display: 'inline-block', overflow: 'hidden' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" style={{backgroundColor: 'transparent'}}>
                <g>
                    <defs>
                        <path id="a" d="m1023.9 765.15c0 5.606-0.171 17.766-0.508 27.159-0.824 22.982-2.646 52.639-5.401 66.151-4.141 20.306-10.392 39.472-18.542 55.425-9.643 18.871-21.943 35.775-36.559 50.364-14.584 14.56-31.472 26.812-50.315 36.416-16.036 8.172-35.322 14.426-55.744 18.549-13.378 2.701-42.812 4.488-65.648 5.3-9.402 0.336-21.564 0.505-27.15 0.505l-504.23-0.081c-5.607 0-17.765-0.172-27.158-0.509-22.983-0.824-52.639-2.646-66.152-5.4-20.306-4.142-39.473-10.392-55.425-18.542-18.872-9.644-35.775-21.944-50.364-36.56-14.56-14.584-26.812-31.471-36.415-50.314-8.174-16.037-14.428-35.323-18.551-55.744-2.7-13.378-4.487-42.812-5.3-65.649-0.334-9.401-0.503-21.563-0.503-27.148l0.08-504.23c0-5.607 0.171-17.766 0.508-27.159 0.825-22.983 2.646-52.639 5.401-66.151 4.141-20.306 10.391-39.473 18.542-55.426 9.643-18.871 21.944-35.775 36.559-50.364 14.584-14.559 31.472-26.812 50.315-36.416 16.037-8.172 35.324-14.426 55.745-18.549 13.377-2.701 42.812-4.488 65.648-5.3 9.402-0.335 21.565-0.504 27.149-0.504l504.23 0.081c5.608 0 17.766 0.171 27.159 0.508 22.983 0.825 52.638 2.646 66.152 5.401 20.305 4.141 39.472 10.391 55.425 18.542 18.871 9.643 35.774 21.944 50.363 36.559 14.559 14.584 26.812 31.471 36.415 50.315 8.174 16.037 14.428 35.323 18.551 55.744 2.7 13.378 4.486 42.812 5.3 65.649 0.335 9.402 0.504 21.564 0.504 27.15l-0.082 504.23z"/>
                    </defs>
                    <linearGradient id="b" x1="512" x2="512" y1=".978" y2="1025" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#61FD7D" offset="0"/>
                        <stop stopColor="#2BB826" offset="1"/>
                    </linearGradient>
                    <use fill="url(#b)" overflow="visible" xlinkHref="#a"/>
                    <clipPath>
                        <use overflow="visible" xlinkHref="#a"/>
                    </clipPath>
                </g>
                <path fill="#FFFFFF"  d="m783.3 243.25c-69.329-69.387-161.53-107.62-259.76-107.66-202.4 0-367.13 164.67-367.21 367.07-0.026 64.699 16.883 127.85 49.017 183.52l-52.096 190.23 194.66-51.047c53.636 29.244 114.02 44.656 175.48 44.682h0.151c202.38 0 367.13-164.69 367.21-367.09 0.039-98.087-38.121-190.32-107.45-259.71zm-259.76 564.8h-0.125c-54.767-0.021-108.48-14.729-155.34-42.529l-11.146-6.612-115.52 30.293 30.834-112.59-7.259-11.544c-30.552-48.579-46.688-104.73-46.664-162.38 0.066-168.23 136.98-305.1 305.34-305.1 81.521 0.031 158.15 31.811 215.78 89.482s89.342 134.33 89.312 215.86c-0.066 168.24-136.98 305.12-305.21 305.12zm167.42-228.52c-9.177-4.591-54.286-26.782-62.697-29.843-8.41-3.062-14.526-4.592-20.645 4.592-6.115 9.182-23.699 29.843-29.053 35.964-5.352 6.122-10.704 6.888-19.879 2.296-9.176-4.591-38.74-14.277-73.786-45.526-27.275-24.319-45.691-54.359-51.043-63.543-5.352-9.183-0.569-14.146 4.024-18.72 4.127-4.109 9.175-10.713 13.763-16.069 4.587-5.355 6.117-9.183 9.175-15.304 3.059-6.122 1.529-11.479-0.765-16.07-2.293-4.591-20.644-49.739-28.29-68.104-7.447-17.886-15.013-15.466-20.645-15.747-5.346-0.266-11.469-0.322-17.585-0.322s-16.057 2.295-24.467 11.478-32.113 31.374-32.113 76.521 32.877 88.764 37.465 94.885c4.588 6.122 64.699 98.771 156.74 138.5 21.892 9.45 38.982 15.094 52.308 19.322 21.98 6.979 41.982 5.995 57.793 3.634 17.628-2.633 54.284-22.189 61.932-43.615 7.646-21.427 7.646-39.791 5.352-43.617s-8.41-6.122-17.585-10.714z"/>
            </svg>
        </div>
    );
}

export default AutomatedRemindersIcon;
