import React from 'react';
import { Formik, Field, Form } from 'formik';
import { 
    Button, 
    Box,
    TextField,
    Toolbar,
    Typography,
    FormControl, 
    InputLabel, 
    Select,
    MenuItem,
    Grid
 } from '@mui/material';
import * as Yup from 'yup';
import BasicDateTimePicker from './shared/BasicDateTimePicker';
import moment from "moment";
import momentTZ from 'moment-timezone';

const validationSchema = Yup.object().shape({
  startAt: Yup.string().required('Requerido'),
  duration: Yup.number().required('Requerido'),
});

const durationOptions = [
  { label: '15 mins', value: 15 },
  { label: '30 mins', value: 30 },
  { label: '45 mins', value: 45 },
  { label: '60 mins', value: 60 },
  { label: '90 mins', value: 90 },
  { label: '120 mins', value: 120 },
];

const serializeEvent = (startAt, duration, summary, id, contact) => {
  let endAt = moment(startAt).add(duration, 'minutes');
  startAt = moment(startAt).format('YYYY-MM-DDTHH:mm:ss');
  endAt = moment(endAt).format('YYYY-MM-DDTHH:mm:ss');

  return {
      'id': id,
      'start': {
          'dateTime': startAt,
          'timeZone': momentTZ.tz.guess()
      },
      'end': {
          'dateTime': endAt,
          'timeZone': momentTZ.tz.guess()
      },
      'attendees': [
          {
              'email': contact.email
          }
      ],
      'summary': summary,
  };
}

const EventForm = ({ onSubmit, onCancel, contact, data, mode }) => {
    const [startAt, setStartAt] = React.useState(data && data.startAt);
    const [duration, setDuration] = React.useState((data && data.duration) || 30);
    const [summary, setSummary] = React.useState(data && data.summary);
    const isNew = mode === 'add';
    const title = isNew ? "Agregar Evento" : "Modificar Evento";
    
    const handleChangeStartAt = e => {
        console.log(e);
        setStartAt(e.format('YYYY-MM-DDTHH:mm:ss'));
    }

    const handleChangeDuration = e => {
        setDuration(e.target.value);
    }

    const handleChangeSummary = e => {
        setSummary(e.target.value);
    }

    const submitEvent = mode => {
      let event = serializeEvent(startAt, duration, summary, data.id, contact);
      onSubmit(mode, event)
    }


  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Typography variant="h6" color="primary" style={{ marginBottom: '20px' }}>
            {title}
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Field
                as={BasicDateTimePicker}
                name="startAt"
                label="Hora"
                fullWidth
                style={{ height: '40px', marginBottom: '10px' }}
                value={startAt}
                onChange={(e) => handleChangeStartAt(e)}
                autoComplete="off"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id='duration-label'>Duración</InputLabel>
                    <Select 
                        labelId='duration-label'
                        id='duration'
                        value={duration}
                        onChange={(e) => handleChangeDuration(e)}
                        >
                            {durationOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Field
                component={TextField}
                name="summary"
                label="Notas"
                multiline
                rows={4}
                fullWidth
                style={{ marginBottom: '10px' }}
                onChange={(e) => handleChangeSummary(e)}
                value={summary}
                autoComplete="off"
              />
            </Grid>
          </Grid>

          <Toolbar style={{ marginTop: '20px', justifyContent: 'space-between' }}>
            <Button variant="outlined" disabled={isSubmitting} onClick={onCancel}>
              Cancelar
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {mode === 'edit' && <Button
                    variant="contained"
                    type="submit"
                    color="error"
                    sx={{marginLeft: "5px"}}
                    onClick={e => submitEvent('delete')}
                >
                    Eliminar
                </Button>}
                <Button
                    variant="contained"
                    type="submit"
                    sx={{marginLeft: "5px"}}
                    onClick={e => submitEvent(mode)}
                >
                    {mode === 'edit' ? 'Confirmar' : 'Aceptar' }
                </Button>
            </Box>
          </Toolbar>
        </Form>
      )}
    </Formik>
  );
};

export default EventForm;
