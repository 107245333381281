function MobileCompatibilityIcon() {
    let fillColor = "#ca4b3a";

    return (
        <div style={{margin: '10px'}}>
            <svg width="80" height="80" viewBox="0 0 455.849 800.00001" xmlns="http://www.w3.org/2000/svg">
                <path d="m64.752 0c-35.872 0-64.752 28.88-64.752 64.752v670.5c0 35.872 28.88 64.752 64.752 64.752h326.35c35.872 0 64.752-28.88 64.752-64.752v-670.5c0-35.872-28.88-64.752-64.752-64.752h-326.35zm3.9492 19.119h318.45c25.795 0 46.561 20.767 46.561 46.562v668.64c0 25.795-20.765 46.562-46.561 46.562h-318.45c-25.795 0-46.562-20.767-46.562-46.562v-668.64c0-25.795 20.767-46.562 46.562-46.562zm116.62 32.906c-7.9648 0-14.377 6.4122-14.377 14.377s6.4122 14.377 14.377 14.377h85.209c7.9648 0 14.377-6.4122 14.377-14.377s-6.4122-14.377-14.377-14.377h-85.209zm-151.65 52.357v567.82h388.51v-567.82h-388.51zm19.924 22.824h348.66v522.17h-348.66v-522.17zm174.33 564.46a36.468 36.468 0 0 0 -36.467 36.467 36.468 36.468 0 0 0 36.467 36.469 36.468 36.468 0 0 0 36.469 -36.469 36.468 36.468 0 0 0 -36.469 -36.467z" 
                fill={fillColor}
                style={{
                    isolation: 'auto',
                    mixBlendMode: 'normal'
                }}
            />
            </svg>
        </div>
    );
}

export default MobileCompatibilityIcon;
