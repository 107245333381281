import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridActionsCellItem } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { blue } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { esES } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMediaQuery } from '@mui/material';

function CustomToolbar({ onAdd, toolbarActions }) {
  return (
    <GridToolbarContainer sx={{ minHeight: '50px' }}>
      <Grid container alignItems="center">
        <Grid item>
          <GridToolbarFilterButton />
        </Grid>
        {toolbarActions.map(a => 
          <Grid item key={a.label}>
            {a.component ? (
              a.component
            ) : (
              <IconButton
                color="primary"
                aria-label={a.label}
                component="span"
                onClick={() => a.onClick()}
                size="small"
                sx={{
                  color: blue[500],
                  fontSize: '0.875rem',
                  fontWeight: 300,
                }}
              >
                <a.icon sx={{ marginRight: '0.3rem', marginLeft: '0.6rem'}}/>
                {a.label}
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
}

function EditableDataGrid({ rows, columns, onEdit, onAdd, onDelete, actions, toolbarActions }) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const defaultSort = columns
  .filter(column => column.hasOwnProperty('sortOrder'))
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map(column => ({
      field: column.field,
      sort: column.sort
  })) || [];
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditRowModelChange = React.useCallback((params) => {
    setEditRowsModel(params.model);
  }, []);

  const flexColumns = [
    ...columns.map((column) => ({
      ...column,
      flex: 1,
    })),
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      renderCell: (params) => {
        if (isMobile) {
          return (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          );
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => onEdit(params.row)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => onDelete(params.row)}
              color="error"
            />,
            ...actions.map(a => (
              <GridActionsCellItem
                icon={a.icon}
                label={a.label}
                onClick={() => a.onClick(params.row)}
              />
            )),
          ];
        }
      },
    }    
  ];



  const hideFields = columns.reduce((result, column) => {
    if (column.hide) {
      result[column.field] = false;
    }
    return result;
  }, {});

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem 
          key={0} 
          onClick={() => onEdit(selectedRow)}>
            <ListItemIcon>
              <DeleteIcon  color='primary'/>
            </ListItemIcon>
            <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem 
          key={1} 
          onClick={() => onDelete(selectedRow)}>
            <ListItemIcon>
              <EditIcon color='error'/>
            </ListItemIcon>
            <ListItemText primary="Delete" />
        </MenuItem>
        {actions.map((action, index) => (
          <MenuItem key={index+2} onClick={() => {
            action.onClick(selectedRow);
            handleClose();
          }}>
            <ListItemIcon>
              {action.icon}
            </ListItemIcon>
            <ListItemText primary={action.label} />
          </MenuItem>
        ))}
      </Menu>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={esES}>
        <DataGrid
          initialState={{
            columns: {
              columnVisibilityModel: hideFields
            },
            sorting: {
              sortModel: defaultSort
            }
          }}
          rows={rows || []}
          columns={flexColumns}
          editRowsModel={editRowsModel}
          onEditRowModelChange={handleEditRowModelChange}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: { onAdd, toolbarActions },
          }}
          density="compact"
        />
      </LocalizationProvider>
    </div>
  );
}

export default EditableDataGrid;
