function EventsVisualizationIcon({ 
    colorBackground = "transparent",
    colorMidground = "#c5c5c5",
    colorForeground = "#607ef4",
    colorBoxes = "#4fc3f7",
    colorHighlight = "#ca4b3a"
}) {
    const style = {
        display: 'block',
        margin: '0px',
        width: '100px',
        height: '100px'
    };
    
    return (
        <svg style={style} viewBox="0 0 722.89293 528.30976" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-13.146 -13.884)">
                <rect x="13.146" y="13.884" width="722.89" height="528.31" ry="5.9508" fill={colorBackground}/>
                <rect x="122" y="113.68" width="475.57" height="352.05" ry="5.9508" fill={colorMidground}/>
                <rect x="137.06" y="95.389" width="475.57" height="352.05" ry="5.9508" fill="#fff"/>
                <rect x="137.06" y="95.389" width="475.57" height="79.197" ry="1.3387" fill={colorForeground}/>
                <rect x="168.88" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="168.88" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="168.88" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="168.88" y="383.57" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="229.15" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="229.15" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="229.15" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="229.15" y="383.57" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="288.33" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="288.33" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="288.33" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="288.33" y="383.57" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="348.6" y="230.68" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="348.6" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="348.6" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="348.6" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="348.6" y="383.57" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="408.86" y="230.68" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="408.86" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="408.86" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="408.86" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="408.86" y="383.57" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="468.7" y="230.68" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="468.7" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="468.7" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="468.7" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorBoxes}/>
                <rect x="530.06" y="230.68" width="48.426" height="27.672" ry="1.3387" fill={colorHighlight}/>
                <rect x="530.06" y="268.04" width="48.426" height="27.672" ry="1.3387" fill={colorHighlight}/>
                <rect x="530.06" y="306.16" width="48.426" height="27.672" ry="1.3387" fill={colorHighlight}/>
                <rect x="530.06" y="344.48" width="48.426" height="27.672" ry="1.3387" fill={colorHighlight}/>
                <rect x="168.88" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorBoxes}/>
                <rect x="229.15" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorBoxes}/>
                <rect x="288.33" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorBoxes}/>
                <rect x="348.6" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorBoxes}/>
                <rect x="408.86" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorBoxes}/>
                <rect x="468.7" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorBoxes}/>
                <rect x="530.06" y="190.3" width="48.426" height="11.53" ry=".55779" fill={colorHighlight}/>
            </g>
        </svg>
    );
}

export default EventsVisualizationIcon;


