import React from 'react';
import emailjs from '@emailjs/browser';
import Notification from './shared/Notification';
import { Button, TextField, Paper, Typography, Container } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  user_name: yup.string('Ingresa tu nombre').required('El nombre es requerido'),
  user_email: yup.string('Ingresa tu email').email('Ingresa un email válido').required('El email es requerido'),
  user_message: yup.string('Ingresa tu mensaje').required('El mensaje es requerido'),
});

const ContactForm = () => {
  const notificationRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      user_name: '',
      user_email: '',
      user_message: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, values, process.env.REACT_APP_EMAILJS_USER_ID)
      .then(() => {
        notificationRef.current.showNotification('success', 'Mensaje enviado satisfactoriamente');
        resetForm();
      }, (error) => {
        notificationRef.current.showNotification('error', 'Error al enviar mensaje ' + error);
      });
  }
});

return (
  <Container component={Paper} maxWidth="sm" style={{ padding: '20px' }}>
    <Notification ref={notificationRef} />
    <Typography variant="h5" gutterBottom>
      Contáctanos
    </Typography>
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        name="user_name"
        label="Nombre"
        value={formik.values.user_name}
        onChange={formik.handleChange}
        error={formik.touched.user_name && Boolean(formik.errors.user_name)}
        helperText={formik.touched.user_name && formik.errors.user_name}
      />
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        name="user_email"
        label="Email"
        type="email"
        value={formik.values.user_email}
        onChange={formik.handleChange}
        error={formik.touched.user_email && Boolean(formik.errors.user_email)}
        helperText={formik.touched.user_email && formik.errors.user_email}
      />
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        name="user_message"
        label="Mensaje"
        multiline
        rows={4}
        value={formik.values.user_message}
        onChange={formik.handleChange}
        error={formik.touched.user_message && Boolean(formik.errors.user_message)}
        helperText={formik.touched.user_message && formik.errors.user_message}
      />
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: '10px' }}
      >
        Enviar
      </Button>
    </form>
  </Container>
);
};

export default ContactForm;