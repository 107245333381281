import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Avatar,
  Tooltip,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MessentiLogo from "../../images/icons/MessentiLogo";
import StripeCheckoutForm from "../StripeCheckoutForm";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Notification from "./Notification";
import WarningIcon from "@mui/icons-material/Warning";
import SettingsIcon from "@mui/icons-material/Settings";
const host = process.env.REACT_APP_API_ENDPOINT;

const ConfirmDialog = ({ open, onClose, onConfirm, title, message }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button onClick={onClose} color="primary">
            No
          </Button>
        )}
        {onConfirm && (
          <Button onClick={onConfirm} color="secondary">
            Sí
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const Subscribe = (props) => {
  const { userContext, setUserContext, setIsSubscribed, notificationRef } =
    props;
  let daysLeft = userContext.daysLeft;
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpenSubscriptionForm = () => {
    setOpen(true);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicStyles = {
    fontSizeButton: isMobile ? "11px" : "14px",
    fontSizeLegend: isMobile ? "10px" : "16px",
    marginLegend: isMobile ? "2px" : "8px",
    maxWidthLegend: isMobile ? "120px" : "400px",
  };

  let legend = "En " + daysLeft;
  legend += daysLeft === 1 ? " día" : " días";
  legend += " expira el periodo de prueba.";

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: fullScreen ? "100%" : "50%",
            bgcolor: "white",
            p: 2,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflowY: "auto",
          }}
        >
          <StripeCheckoutForm
            notificationRef={notificationRef}
            userContext={userContext}
            setUserContext={setUserContext}
            setIsSubscribed={setIsSubscribed}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Box
        component="span"
        sx={{
          animation:
            "blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate",
          marginRight: dynamicStyles.marginLegend,
          fontSize: dynamicStyles.fontSizeLegend,
          maxWidth: dynamicStyles.maxWidthLegend,
        }}
      >
        {legend}
      </Box>
      <Button
        onClick={handleOpenSubscriptionForm}
        variant="outlined"
        style={{ borderColor: "white", color: "white", marginRight: "8px", fontSize: dynamicStyles.fontSizeButton }}
      >
        Suscribírse
      </Button>
      <LoggedIn userContext={userContext} />
    </Box>
  );
};

const ViewSubscription = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [confirmDialogModel, setConfirmDialogModel] = React.useState({});
  const [openUpdatePaymentMethodDialog, setOpenUpdatePaymentMethodDialog] =
    React.useState(false);
  const [updatePaymentMethodDialogModel, setUpdatePaymentMethodDialogModel] =
    React.useState({});
  const { userContext, logout } = props;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenConfirmCancelDialog = () => {
    setConfirmDialogModel({
      title: "Confirmar Cancelación",
      message: (
        <p>
          <font color="red">
            ¿Estás seguro de que deseas cancelar tu suscripción?
          </font>
          <br />
          Será dirigido automáticamente fuera de la aplicación y se dejarán de
          enviar recordatorios a sus clientes a partir de hoy.
        </p>
      ),
      onClose: handleCloseConfirmDialog,
      onConfirm: handleCancelSubscription,
    });
    setOpenConfirmDialog(true);
  };

  const handleOpenUpdatePaymentDialog = () => {
    axios
      .post(`${host}/api/stripechangepaymentmethod/${userContext.email}`)
      .then((result) =>
        axios.post(`${host}/api/sendemail/${userContext.email}`, result)
      )
      .then((result) => {
        setUpdatePaymentMethodDialogModel({
          message: (
            <p>
              Se he enviado un correo a {userContext.email} con las
              instrucciones para actualizar su método de pago. Favor de revisar
              su bandeja de correos no deseados.
            </p>
          ),
        });
        setOpenUpdatePaymentMethodDialog(true);
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setOpenDialog(false);
  };

  const handleCancelSubscription = () => {
    axios
      .post(`${host}/api/stripecancelsubscription/${userContext.email}`)
      .then((result) => {
        logout();
        handleCloseConfirmDialog();
      })
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <DialogUpdatePaymentMethod
        open={openUpdatePaymentMethodDialog}
        message={updatePaymentMethodDialogModel.message}
      />
      <ConfirmDialog open={openConfirmDialog} {...confirmDialogModel} />
      <LoggedIn userContext={userContext} />
      <Tooltip title="Ver y actualizar suscripción">
        <IconButton color="inherit" onClick={handleOpenDialog}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Detalles de la Suscripción</DialogTitle>
        <DialogContent>
          <p>Plan Actual: Pago mensual 299.00 MXN</p>
          <p>
            Inicio de suscripción:{" "}
            {formatDate(userContext.subscription.createdDate)}
          </p>
        </DialogContent>
        <DialogActions>
          <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
            <Button
              onClick={handleOpenConfirmCancelDialog}
              color="error"
              variant="outlined"
              fullWidth
            >
              Cancelar Suscripcion
            </Button>
            <Button
              onClick={handleOpenUpdatePaymentDialog}
              color="primary"
              variant="outlined"
              fullWidth
            >
              Actualizar Método de Pago
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const DialogUpdatePaymentMethod = (props) => {
  let { message, handleClose } = props;
  const [open, setOpen] = React.useState(props.open);

  const onClose = () => {
    setOpen(false);

    handleClose && handleClose();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <WarningIcon
          style={{
            color: "orange",
            marginRight: "4px",
            fontSize: "1.3rem",
            verticalAlign: "middle",
          }}
        />{" "}
        Alerta
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RenewSubscription = (props) => {
  let { userContext, logout } = props;
  let message = `Hubo un problema al utilizar su método de pago, se enviará un correo a ${userContext.email} con las instrucciones para actualizar su método de pago. Favor de revisar su bandeja de correos no deseados.`;

  let handleClose = () => {
    logout();
  };

  useEffect(() => {
    axios
      .post(`${host}/api/stripechangepaymentmethod/${userContext.email}`)
      .then((result) =>
        axios.post(`${host}/api/sendemail/${userContext.email}`, result)
      )
      .catch((error) => {
        console.log("Error: ", error.message);
      });
  }, [userContext.email]);

  return (
    <DialogUpdatePaymentMethod
      open={true}
      message={message}
      handleClose={handleClose}
    />
  );
};

const Subscription = (props) => {
  let { userContext, setUserContext, logout } = props;
  const notificationRef = React.useRef();
  const [isSubscribed, setIsSubscribed] = useState(!!userContext.subscription);

  let status = null;
  if (
    userContext &&
    userContext.subscription &&
    userContext.subscription.status
  ) {
    status = userContext.subscription.status;
    // if (userContext.subscription.status == 'Active')  {

    // } else if (userContext.subscription.status == 'PaymentFailed')  {

    // }
  }

  useEffect(() => {
    if (
      status === "Canceled" ||
      (!userContext.subscription && userContext?.daysLeft < 1)
    ) {
      setIsSubscribed(false);
      notificationRef.current.showNotification(
        "error",
        `Su periodo de prueba a terminado o su subscripción ha expirado, no se podrán enviar mensajes a sus clientes hasta activar su suscripción.`
      );
    }
    if (status === "Created") {
      setIsSubscribed(false);
    }
  }, [
    userContext,
    userContext.subscription,
    userContext.daysLeft,
    isSubscribed,
    status,
  ]);

  return (
    <div>
      <Notification ref={notificationRef} />
      {(!isSubscribed || status === "Canceled") && (
        <Subscribe
          userContext={userContext}
          setUserContext={setUserContext}
          setIsSubscribed={setIsSubscribed}
          notificationRef={notificationRef}
        />
      )}
      {isSubscribed && status === "PaymentFailed" && (
        <RenewSubscription userContext={userContext} logout={logout} />
      )}
      {isSubscribed && (status === "Active" || status === "Created") && (
        <ViewSubscription userContext={userContext} logout={logout} />
      )}
    </div>
  );
};

const LoggedIn = (props) => {
  let { userContext } = props;

  let qName = userContext?.name;
  let name = "";
  const isNameArray = Array.isArray(qName);
  if (isNameArray) {
    name = name[0];
  } else {
    name = userContext?.name;
  }
  name && name.toUpperCase();
  const acronym = name && name.charAt(0).toUpperCase();

  return (
    <Avatar alt={name} src={userContext?.picture}>
      {acronym}
    </Avatar>
  );
};

const NavigationBar = ({ userContext, setUserContext }) => {
  const logout = () => {
    window.FB.logout(function (response) {
      console.log("User logged out", response);
      setUserContext(null);
    });
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  const dynamicStyles = {
    iconMargin: isMobile ? "3px" : "10px",
    iconSize: isMobile ? 40 : 40,
    paddingToolbar: isMobile ? "2px" : "2px",
  };

  return (
    <AppBar position="fixed" color="primary" sx={{ padding: "0px" }}>
      <Toolbar sx={{ padding: dynamicStyles.paddingToolbar }}>
        <MessentiLogo
          margin={dynamicStyles.iconMargin}
          width={dynamicStyles.iconSize}
          height={dynamicStyles.iconSize}
        />
        <Box sx={{ flex: 1 }} />
        {userContext !== null && (
          <Subscription
            userContext={userContext}
            setUserContext={setUserContext}
            logout={logout}
          />
        )}
        <Tooltip title="Cerrar sesión">
          <IconButton color="inherit" onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default NavigationBar;
