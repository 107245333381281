import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
<div>
<h1>Política de Privacidad de Messenti</h1>

    <p><strong>Fecha de la última revisión:</strong> 18-Agosto-2024</p>    

    <h2>1. Introducción</h2>
<p>
    Bienvenido a Messenti. Nos comprometemos a proteger la privacidad de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos, divulgamos y salvaguardamos su información cuando utiliza nuestra aplicación. Por favor, lea esta política de privacidad cuidadosamente.
</p>

<h2>2. Información que recopilamos</h2>
<ul>
    <li><strong>Información del calendario:</strong> Accedemos a un calendario en Google creado por nuestra aplicación para administrar y recordar visitas.</li>
    <li><strong>Información de contactos:</strong> Accedemos a la lista de contactos del usuario en Google y administramos dicha lista para el propósito de enviar recordatorios.</li>
</ul>

<h2>3. Cómo utilizamos su información</h2>
<p>
    Utilizamos la información recopilada de la siguiente manera:
</p>
<ul>
    <li><strong>Administrar visitas:</strong> Para enviar recordatorios de visita a través de mensajes a aplicaciones de mensajería.</li>
    <li><strong>Administrar contactos:</strong> Para acceder y administrar la lista de contactos del usuario.</li>
    <li><strong>Administrar eventos:</strong> Para gestionar los eventos en el calendario creado por nuestra aplicación.</li>
</ul>

<h2>4. Cómo protegemos su información</h2>
<ul>
    <li><strong>Almacenamiento de datos:</strong> No almacenamos datos de clientes, eventos o cualquier otra información relacionada en una base de datos externa.</li>
    <li><strong>Uso de credenciales:</strong> No utilizamos la credencial del usuario para ningún propósito ajeno al funcionamiento principal de nuestra aplicación.</li>
</ul>

<h2>5. Divulgación de su información</h2>
<p>
    No vendemos, intercambiamos ni transferimos a terceros su información personalmente identificable sin su consentimiento.
</p>

<h2>6. Derechos del usuario</h2>
<ul>
    <li><strong>Acceso y rectificación:</strong> Puede acceder y corregir su información en cualquier momento a través de la aplicación.</li>
    <li><strong>Eliminación:</strong> Puede solicitar la eliminación de su cuenta y la información asociada en cualquier momento. Para solicitar la eliminación de tus datos, visita <Link to="/DataDeletionRequest" style={styles.link}>Solicitud de eliminación de datos de usuario</Link>. Allí encontrarás instrucciones detalladas sobre el proceso y cómo proceder.</li>
</ul>

<h2>7. Cambios en esta Política de Privacidad</h2>
<p>
    Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio que hagamos se reflejará en esta página.
</p>

<h2>8. Contacto</h2>
<p>
    Si tiene alguna pregunta o comentario sobre esta Política de Privacidad, por favor, póngase en contacto con nosotros en <a href="mailto:info@messenti.com">info@messenti.com</a>.
</p>
<br ></br>
<br ></br>
    <Link to="/" style={styles.link}>Regresar</Link>
    </div>);
}


const styles = {
    link: {
        textDecoration: 'none', 
        color: 'blue',
        cursor: 'pointer'  
      }
  };

export default PrivacyPolicy;