import { Link } from 'react-router-dom';

const ServiceConditions = () => {
    return (
<div>
<h1>Condiciones de Servicio de Messenti</h1>
<p><strong>Última actualización:</strong> 31-Octubre-2023</p>

<h2>1. Aceptación de las Condiciones de Servicio</h2>
<p>Al acceder o utilizar Messenti, usted acepta cumplir y estar sujeto a estas Condiciones de Servicio. Si no está de acuerdo con alguna parte de estas condiciones, no utilice ni acceda a nuestros servicios.</p>

<h2>2. Descripción del Servicio</h2>
<p>Messenti es una herramienta que envía mensajes electrónicos a sus clientes para recordarles sobre sus visitas programadas. Para ello, la aplicación accede a un calendario en Google creado específicamente para este propósito, así como a la lista de contactos del usuario en Google para administrar y enviar recordatorios.</p>

<h2>3. Uso Responsable</h2>
<p>Al utilizar Messenti, usted garantiza que:</p>
<ul>
    <li>Utilizará el servicio solo para fines permitidos y de acuerdo con estas Condiciones.</li>
    <li>No infringirá derechos ni actuará de manera que cause daño a individuos o entidades.</li>
    <li>Proporcionará información veraz y actualizada en su lista de contactos y en los eventos programados.</li>
</ul>

<h2>4. Privacidad y Seguridad</h2>
<p>Nos comprometemos a:</p>
<ul>
    <li>No almacenar datos de clientes, eventos o cualquier otra información relacionada en una base de datos externa.</li>
    <li>No utilizar la credencial del usuario para ningún propósito ajeno al funcionamiento principal de nuestra aplicación.</li>
    <li>Garantizar la confidencialidad y seguridad de los datos accedidos.</li>
</ul>

<h2>5. Limitaciones y Responsabilidades</h2>
<p>Messenti se proporciona "tal cual" y "según disponibilidad". No garantizamos que nuestro servicio estará libre de errores o interrupciones. Hasta el límite máximo permitido por la ley, no seremos responsables por daños de cualquier tipo que surjan del uso de nuestro servicio.</p>

<h2>6. Derechos de Autor y Propiedad Intelectual</h2>
<p>Todo el contenido incluido en Messenti es propiedad de Etherea y está protegido por leyes de derechos de autor y otros derechos de propiedad intelectual.</p>

<h2>7. Terminación</h2>
<p>Nos reservamos el derecho de suspender o terminar su acceso a Messenti en cualquier momento, con o sin motivo y con o sin previo aviso.</p>

<h2>8. Cambios en las Condiciones de Servicio</h2>
<p>Nos reservamos el derecho de modificar o revisar estas Condiciones de Servicio en cualquier momento. Cualquier cambio se publicará en esta página.</p>

<h2>9. Disputas y Ley Aplicable</h2>
<p>Cualquier disputa relacionada con Messenti se regirá por las leyes de México, sin tener en cuenta sus conflictos de disposiciones legales.</p>

<h2>10. Contacto</h2>
<p>Para cualquier consulta o inquietud relacionada con estas Condiciones de Servicio, contáctenos en: <a href="mailto:info@messenti.com">info@messenti.com</a>.</p>

<br ></br>
<br ></br>
    <Link to="/" style={styles.link}>Regresar</Link>
    </div>);
}


const styles = {
    link: {
        textDecoration: 'none', 
        color: 'blue',
        cursor: 'pointer'  
      }
  };

export default ServiceConditions;