import { Link } from 'react-router-dom';
import DataDeletionRequestForm from './DataDeletionRequestForm';

const DataDeletionRequest = () => {
    return (
<div>
<h1>Solicitud de eliminación de datos de usuario</h1>
<p><strong>Última actualización:</strong> 8-Agosto-2024</p>


<p>En Messenti estamos comprometidos con tu privacidad. Si deseas eliminar tu cuenta y todos los datos asociados, completa la forma abajo, recibirás un correo electrónico para confirmar tu solicitud. Ten en cuenta que este proceso es irreversible y puede tardar hasta 30 días.</p>
<p>Ten en cuenta que <strong>una vez que tu cuenta sea eliminada, perderás el acceso a todos los servicios y datos asociados a tu cuenta. La eliminación es permanente y no podrás recuperar tu información.</strong></p>
<p>Para cualquier duda o asistencia adicional, contáctanos en <a href="mailto:info@messenti.com">info@messenti.com</a>.</p>


<br ></br>
<DataDeletionRequestForm />
<br ></br>
    <Link to="/" style={styles.link}>Regresar</Link>
    </div>);
}


const styles = {
    link: {
        textDecoration: 'none', 
        color: 'blue',
        cursor: 'pointer'  
      }
  };

export default DataDeletionRequest;