function MessentiLogo(props) {
    let { margin, height, width } = props;
    
    return (
        <div style={{ margin: margin??'10px', display: 'inline-block', overflow: 'hidden' }}>
            <svg width={width??"200"} height={height??"200"} viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
                <circle cx="150" cy="150" r="150" fill="##FF6AB1" />
                <circle cx="80" cy="85" r="20" fill="white" />
                <circle cx="80" cy="150" r="20" fill="white" />
                <circle cx="80" cy="215" r="20" fill="white" />
                <circle cx="220" cy="85" r="20" fill="white" />
                <circle cx="220" cy="150" r="20" fill="white" />
                <circle cx="220" cy="215" r="20" fill="white" />
                <circle cx="150" cy="135" r="20" fill="white" />
            </svg>
        </div>
    );
}

export default MessentiLogo;
