import React, { useState, useEffect, useCallback } from 'react';
import axios, { CancelToken } from 'axios';
import EditableDataGrid from './shared/EditableDataGrid';
import Notification from './shared/Notification';
import Appointments from './Appointments';
import ContactForm from './ContactForm';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import AddIcon from '@mui/icons-material/Add';
import DraftsIcon from '@mui/icons-material/Drafts';
import moment from "moment";
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import MessageTemplateForm from './MessageTemplateForm';
import Alert from './shared/Alert';
import { useMediaQuery } from '@mui/material';
import { CalendarView } from 'kalend';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const host = process.env.REACT_APP_API_ENDPOINT;

const Contacts = (props) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [formMode, setFormMode] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openAppointment, setOpenAppointment] = React.useState(false);
  const [appointments, setAppointments] = React.useState([]);
  const [contacts, setContacts] = useState(null);
  const { contextUser, setContextUser } = props;
  const [showAlert, setShowAlert] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [alertModel, setAlertModel] = useState(false);
  const notificationRef = React.useRef();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseAppointment = () => 
{ 
  setOpenAppointment(false);
}
  const source = CancelToken.source();
  const contactValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email debe ser un email válido').required('Email es requerido'),
    phoneNumber: Yup.string().required('Celular es requerido').matches(/^\(\d{2}\) \d{3}-\d{5}$/, 'Número de celular debe ser un número válido (XX) XXX-XXXXX'),
  });
  
  const handleOpenAppointment = (contact) => {
    setSelectedContact(contact);

    contactValidationSchema.validate(contact).then(() => {
      fetchAppointments(contact.email).then(appointments => {
        setAppointments(appointments);
        setOpenAppointment(true);
      });
    }).catch(error => {
      let errorMessage = 'Error de validacion: ' + error.message + '\n¿Deseas corregir los datos del contacto?';
      setShowAlert(true);
      setAlertModel({
        message: errorMessage,
        onAccept: handleAcceptUpdateContact
      });
    });
  };
  const columns = [
    { field: 'etag', hide: true },
    { field: 'resourceName', hide: true },
    { field: 'firstName', headerName: 'Nombre', width: 150, sortOrder: 0, sort: 'asc' },
    { field: 'lastName', headerName: 'Apellido', width: 150, sortOrder: 1, sort: 'asc' },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phoneNumber', headerName: 'Celular', width: 200 }
  ]
  const actions = [
    {
        icon:<CalendarIcon color='primary'/>,
        label:"Citas",
        onClick:handleOpenAppointment
    }
  ]
  const toolbarActions = [
    {
      icon:AddIcon,
      label:"AGREGAR",
      onClick:() => handleAdd()
    },
    {
      icon:DraftsIcon,
      label:"PLANTILLA",
      onClick:() => setOpenForm(true)
    }
  ]

  const formatEventData = (data) => {
    let events = data.items.filter(i => 
            i.start?.datetime && 
            i.end?.datetime && 
            moment(i.start.datetime).isValid() && 
            moment(i.end.datetime).isValid()
        ).map(e => {
        let id = e.id;
        let start = moment(e.start.datetime);
        let end = moment(e.end.datetime);
        let duration = moment.duration(end.diff(start));
        let date = moment(start).toDate();
        let hour = moment(start).format('HH:mm');
        let summary = e.summary;

        return {
            'date': date,
            'hour': hour,
            'duration': duration.asMinutes(),
            'durationDesc': duration.humanize(),
            'attendees': e.attendees,
            id: id,
            startAt: e.start.datetime, //'2021-11-21T18:00:00.000Z',
            endAt: e.end.datetime, //'2021-11-21T19:00:00.000Z',
            // timezoneStartAt: 'Europe/Berlin', // optional
            summary: summary || '',
            color: 'blue',
            calendarID: 'work'
        }
    });

    return events;
  }

  const fetchAppointments = async (contactId) => {
    return await axios.get(
      `${host}/api/events/${contextUser.email}/${contactId}`, 
      {
        cancelToken: source.token
      }).then(result => formatEventData(result.data))
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.log('Error: ', error.message);
        }
      });
  }

  const fetchContacts = useCallback(async () => {
    try {
      const response = await axios.get(
        `${host}/api/contacts/${contextUser.email}`,
        {
          cancelToken: source.token
        }).catch(error => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            console.log('Error: ', error.message);
          }
        });
  
      let contacts = response.data.connections?.map((contact) => {
        
        return {
          etag: contact.etag,
          resourceName: contact.resourceName,
          id: contact.resourceName,
          firstName: contact.names?contact.names[0].givenName:undefined,
          lastName: contact.names?contact.names[0].familyName:undefined,
          email: contact.emailAddresses?contact.emailAddresses[0].value:undefined,
          phoneNumber: (contact.phoneNumbers && contact.phoneNumbers.filter(p => p.type === "mobile").length > 0)?contact.phoneNumbers.filter(p => p.type === "mobile")[0].value:undefined,
        };
      });
  
      if (!contacts) contacts = [];
  
      setContacts(contacts);
    } catch (error) {
      console.error('Failed to fetch contacts:', error);
    }
  }, [contextUser.email, source.token]);

  const serializeContact = (data) => {
    return {
      etag: data.etag,
      emailAddresses: [{
        value: data.email
      }],
      names: [{
        givenName: data.firstName,
        familyName: data.lastName
      }],
      phoneNumbers: [{
        value: data.phoneNumber,
        type: "mobile",
        formattedType: "Mobile"
      }],
    }
  }

  const handleSubmit = async(data) => {
    let contact = serializeContact(data);
    let request = null;

    if (formMode === 'add') {
      request = axios.post(
        `${host}/api/contacts/${contextUser.email}`, 
        contact);
    } else if (formMode === 'edit') {
      request = axios.put(
        `${host}/api/contacts/${contextUser.email}/${data.resourceName}`,
        contact);
    } else if (formMode === 'delete') {
      request = axios.delete(
        `${host}/api/contacts/${contextUser.email}/${data.resourceName}`,
        contact);
    }    
    

    await request.then(response => {
      if (response) {
        notificationRef.current.showNotification('success', 'Operación realizada con éxito');

        handleClose();
        
        return response;
      }
    }).then(response => {
      if (response) {
        // fetchContacts();

        setTimeout(() => {
          fetchContacts();
        }, formMode === 'add' ? 10000 : 0)
      }
    }).catch(error => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
      notificationRef.current.showNotification('error', 'Se produjo un error en la operación');
    });
  };

  const handleSubmitEvent = async(mode, data) => {
    let request = null;

    if (mode === 'add') {
      request = axios.post(
        `${host}/api/events/${contextUser.email}`,
        data);
    } else if (mode === 'edit') {
      request = axios.put(
        `${host}/api/events/${contextUser.email}/${data.id}`,
        data);
    } else if (mode === 'delete') {
      request = axios.delete(
        `${host}/api/events/${contextUser.email}/${data.id}`,
        {
          cancelToken: source.token
        });
    }    
    

    await request.then(response => {
      if (response) {
        notificationRef.current.showNotification('success', 'Operación realizada con éxito');

        fetchAppointments(selectedContact.email).then(appointments => {
          setAppointments(appointments);
        })
      }

      return response;
    }).catch(error => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
      notificationRef.current.showNotification('error', 'Se produjo un error en la operación');
    });
  };

  const handleSubmitSettings = async(values, templateFields, resetValues) => {
    debugger;
    let messageTemplateId = templateFields?.length > 0 && templateFields[0].messageTemplateId;
    let templateFieldsWithValues = [...templateFields];
    Object.keys(values).forEach(id => {
      let field = templateFieldsWithValues.find(f => f.id === id);
      if (field) {
        field.value = values[id];
      }
    });
    
    var data = {
      TimeZone: values.TimeZone,
      TemplateFields: templateFieldsWithValues
    }
              
    axios.put(`${host}/api/settings/${contextUser.email}`, data)
    .then(data => {
      contextUser.messageTemplateId = messageTemplateId;
      setContextUser(contextUser);
      setOpenForm(false);
      resetValues();
      notificationRef.current.showNotification('success', 'Operación realizada con éxito');
    }).catch(error => {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
      notificationRef.current.showNotification('error', 'Se produjo un error en la operación');
    })
  };

  const handleAdd = () => {
    handleOpen();
    setSelectedContact({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
    setFormMode('add');
  }

  const handleEdit = (contact) => {
    handleOpen();
    setSelectedContact(contact);
    setFormMode('edit');
  }

  const handleDelete = (contact) => {
    handleOpen();
    setSelectedContact(contact);
    setFormMode('delete');
  }

  const handleCancel = (contact) => {
    handleClose();
  }

  const handleAcceptMissingTemplate = () => {
    setShowAlert(false);
    setOpenForm(true)
  }

  const handleAcceptUpdateContact = () => {
    setShowAlert(false);
    handleEdit(selectedContact);
  }

  const handleShowAlert = (show) => {
    setShowAlert(show);
  }

  useEffect(() => {
    if (contextUser?.email && contacts === null) {
      fetchContacts();
    }
    if (!contextUser?.messageTemplateId && showAlert === null) {
      setShowAlert(true);
      setAlertModel({
        message: 'No se ha definido la plantilla para enviar los recordatorios a tus clientes.\n¿Deseas definirla en este momento?',
        onAccept: handleAcceptMissingTemplate
      })
    }
  }, [contextUser, fetchContacts, contacts, showAlert]);


  
  

  return (
    <>
      <Alert 
        open={showAlert} 
        setOpen={handleShowAlert} 
        {...alertModel}/>

      <MessageTemplateForm 
        open={openForm} 
        setOpen={setOpenForm} 
        contextUser={contextUser}
        handleSubmit={handleSubmitSettings} 
      />
      <Typography variant="h4" color="primary"  component="h2" gutterBottom>Contactos</Typography>
      <div style={{ height: 400, width: '100%' }}>
        <Notification ref={notificationRef} />
        <EditableDataGrid
          rows={contacts}
          columns={columns}
          onCancel={handleCancel}
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDelete}
          actions={actions}
          toolbarActions={toolbarActions}
        />
        <Modal open={open} onClose={handleClose}>
          <Box 
              sx={{ 
                width: isMobile?'95%':'50%', 
                bgcolor: 'white', 
                p: 2, 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)', 
                overflowY: 'auto',
              }}
            >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <ContactForm 
              mode={formMode} 
              onSubmit={handleSubmit} 
              onCancel={handleCancel} 
              initialValues={selectedContact} />
            </Box>
        </Modal>

        <Modal open={openAppointment} onClose={handleCloseAppointment}>
          <Box 
              sx={{ 
                width: isMobile? '95%':'70%', 
                bgcolor: 'white', 
                p: 2, 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)', 
                overflowY: 'auto'
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleCloseAppointment}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  zIndex: 1
                }}
              >
                <CloseIcon />
              </IconButton>
            <Appointments 
              mode={formMode} 
              onSubmit={handleSubmit} 
              onCancel={handleCancel} 
              initialView={isMobile?CalendarView.DAY:CalendarView.WEEK}
              initialValues={selectedContact} 
              data={appointments}
              contact={selectedContact}
              handleSubmitEvent={handleSubmitEvent}
              />
            </Box>
        </Modal>
      </div>
    </>
  );
};

export default Contacts;
