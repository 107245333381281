import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contacts from './components/Contacts';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import DataDeletionRequest from './components/DataDeletionRequest';
import ServiceConditions from './components/ServiceConditions';
import NavigationBar from './components/shared/NavigationBar';
import UserContext from './components/shared/UserContext';
import MetaBusinessIdentity from './components/MetaBusinessIdentity'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { styled } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const AppContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  //marginLeft: theme.spacing(2),
  marginTop: theme.spacing(10),
  [theme.breakpoints.up('sm')]: {
    //marginLeft: theme.spacing(2),
    marginTop: theme.spacing(13),
    //marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const App = () => {
  const [contextUser, setContextUser] = React.useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={esES}>
      <GoogleOAuthProvider clientId="367652261086-l39eq865koj682c0qbk4l9j5goos6rh0.apps.googleusercontent.com">
        <AppContainer>
          <BrowserRouter>
            <UserContext.Provider value={contextUser}>
              {contextUser && <NavigationBar userContext={contextUser} setUserContext={setContextUser} />}  
              <Routes>
                <Route path="/" element={contextUser ? <Contacts contextUser={contextUser} setContextUser={setContextUser}/> : <Home setContextUser={setContextUser}/>} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}/>
                <Route path="/DataDeletionRequest" element={<DataDeletionRequest />}/>
                <Route path="/ServiceConditions" element={<ServiceConditions />}/>
                <Route path="/MetaBusinessIdentity" element={<MetaBusinessIdentity />}/>
              </Routes>
            </UserContext.Provider>
          </BrowserRouter>
        </AppContainer>
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
};

export default App;
