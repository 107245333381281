import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import WarningIcon from '@mui/icons-material/Warning';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const Alert = ({ open, setOpen, onAccept, message }) => {

  // useEffect(() => {
    
  // }, [open]);


  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <WarningIcon style={{ color: 'orange', marginRight: '4px', fontSize: '1.3rem', verticalAlign: 'middle' }} /> Alerta
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Ignorar
        </Button>
        <Button onClick={() => onAccept()} color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Alert;