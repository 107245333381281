import React, { useState, useEffect } from 'react';
import {
  Typography,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Kalend from 'kalend';
import 'kalend/dist/styles/index.css';
import EventForm from './EventForm';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Appointments = props => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { 
    data, 
    handleSubmitEvent, 
    contact, 
    initialView, 
  } = props;
  const handleCloseEvent = () => setOpenEvent(false);
  const handleOpenEvent = () => setOpenEvent(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openEvent, setOpenEvent] = React.useState(false);
  const [formMode, setFormMode] = useState(null);
  const [value, setValue] = React.useState(0);
  const columns = [
    {
      field: 'date',
      headerName: 'Fecha',
      type: 'date',
      flex: 1
    },
    {
        field: 'hour',
        headerName: 'Hora',
        flex: 1
      },
    {
      field: 'duration',
      headerName: 'Duración (mins)',
      flex: 1
    },
  ]
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async(mode, data) => {
    handleSubmitEvent(mode, data);
    handleCloseEvent();
  };

  const onEventClick = (e) => {
      setFormMode('edit');
      // let data = {...e, startAt: dayjs(e.startAt)};
      setSelectedEvent(e);
      handleOpenEvent();
  }

  const onNewEventClick = (e) => {
      setFormMode('add');
      // let data = {...e, startAt: dayjs(e.startAt)};
      setSelectedEvent(e);
      handleOpenEvent();
  }

  const onEventDragFinish = (prev, current, data) => {
      //let cancelEvent = true;
    };

  // const selectedView = () => {
  //     console.log('selectedView');
  // }

  // const onPageChange = () => {
  //     console.log('onPageChange');
  // }

  
/*
const onNewEventClick = (data: any) => {
  const msg = `New event click action\n\n Callback data:\n\n${JSON.stringify({
    hour: data.hour,
    day: data.day,
    startAt: data.startAt,
    endAt: data.endAt,
    view: data.view,
    event: 'click event ',
  })}`;
  console.log(msg);
};

// Callback for event click
const onEventClick = (data: any) => {
  const msg = `Click on event action\n\n Callback data:\n\n${JSON.stringify(
    data
  )}`;
  console.log(msg);
};

// Callback after dragging is finished
const onEventDragFinish: OnEventDragFinish = (
  prev: any,
  current: any,
  data: any
) => {
  setDemoEvents(data);
};*/

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography height={'65vh'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const handleCancel = () => {
  handleCloseEvent();
}

useEffect(() => {
  
}, [data]);

  return <LocalizationProvider dateAdapter={AdapterMoment}>      
      <Box sx={{width: '100%'}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} >
                  <Tab label="Calendario" {...a11yProps(0)} />
                  <Tab label="Historial" {...a11yProps(1)} />
              </Tabs>
          </Box>
      </Box>
      <TabPanel value={value} index={0} >
      <Kalend
                  kalendRef={props.kalendRef}
                  onNewEventClick={onNewEventClick}
                  initialView={initialView}
                  //disabledViews={[CalendarView.THREE_DAYS]}
                  onEventClick={onEventClick}
                  events={data}
                  initialDate={new Date().toISOString()}
                  hourHeight={60}
                  language={'es'}
                  isNewEventOpen={false}
                  maxHeight={'900px'}
                  resizeDisabledConditions={{
                      allDay: false
                  }}
                  // showWeekNumbers={true}
                  //timezone={'Europe/Berlin'}
                  // draggingDisabledConditions={{
                  //   summary: 'Computers',
                  //   allDay: false,
                  //   color: 'pink',
                  // }}
                  onEventDragFinish={onEventDragFinish}
                  onStateChange={props.onStateChange}
                  // selectedView={selectedView}
                  // onSelectView={e => onSelectView(e)}
                  showTimeLine={true}
                  isDark={false}
                  autoScroll={true}
                  disabledDragging={true}
                  // colors={{
                  //   light: {
                  //     primaryColor: 'blue',
                  //   },
                  //   dark: {
                  //     primaryColor: 'orange',
                  //   },
                  // }}
                  />
      </TabPanel>
      <TabPanel value={value} index={1}>
          {<DataGrid
                  //data={data && data.filter(i => i.attendees && i.attendees.filter(a => a.email == selectedContact).length > 0) || []}
                  initialState={{
                    sorting: {
                      sortModel: [{
                        field: 'date', sort: 'asc'
                      }]
                    }
                  }}
                  rows={data}
                  columns={columns}
                  density="compact"
              />}
      </TabPanel>

      <Modal open={openEvent} onClose={handleCloseEvent}>
        <Box 
            sx={{ 
              width: isMobile?'95%':'50%', 
              bgcolor: 'white', 
              p: 2, 
              position: 'absolute', 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              overflowY: 'auto'
            }}
          >
          <IconButton
            aria-label="close"
            onClick={handleCloseEvent}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <EventForm 
            mode={formMode} 
            onSubmit={handleSubmit} 
            onCancel={handleCancel} 
            data={selectedEvent}
            contact={contact}
            />
          </Box>
      </Modal>
  </LocalizationProvider>
}

export default Appointments;
