import { Link } from 'react-router-dom';

const MetaBusinessIdentity = () => {
    return (
<div>
<h1>Identificación Comercial Meta</h1>
<p><strong>Última actualización:</strong> 5-Abril-2024</p>
<b>Messenti</b> es una aplicación que propociona servicios de envío de mensajes electrónicos a nuestros usuarios vía <b>Whatsapp</b> para recordar a sus clientes sobre sus próximas visitas programadas. <br></br>
            Para ello, la aplicación accede a servicios proporcionados por <b>Meta</b>, registrada bajo la siguiente identidad:
<ul>
    <li>Número de identificación: 345788933555967</li>
    <li>Nombre legal del negocio: <b>Juan Manuel Ramos Vargas</b></li>
    <li>Identificador fiscal (RFC): RAVJ8009096N2</li>
    <li>Correo electrónico: <a href="mailto:info@messenti.com">info@messenti.com</a></li>
    <li>Teléfono móvil: (+52) 8185250571</li>
    <li>Dirección: Yucatán 576 Col. República Ote, Saltillo, Coah, México</li>
    <li>Código Postal: 25280</li>
</ul>
<br ></br>
<br ></br>
    <Link to="/" style={styles.link}>Regresar</Link>
    </div>);
}


const styles = {
    link: {
        textDecoration: 'none', 
        color: 'blue',
        cursor: 'pointer'  
      }
  };

export default MetaBusinessIdentity;