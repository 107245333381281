import React from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

function PhoneField({ name, touched, errors, ...props }) {
  return (
    <InputMask
      mask="(99) 999-99999"
      {...props}
    >
      {() => 
        <TextField
          {...props}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          type="tel"
          name="phoneNumber"
          label="Celular"
          fullWidth
          autoComplete="no-autocomplete"
          autoCorrect="off"
          spellCheck="false"
        />
      }
    </InputMask>
  );
}

export default PhoneField;